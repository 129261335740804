import { routerLogin } from '@view/Auth/Login/router';
import { routerViewProfile } from '@view/Auth/Profiles/router';
import { routerLogs } from '@view/Customer/Logs/router';
import { routerCustomer } from '@view/Customer/router';
import { routerDevice } from '@view/Device/router';
import { routerHome } from '@view/Home/router';
import { routerPageError } from '@view/PageError/router';
import { routerRoles, routerRolesAdd, routerRolesEdit, routerRolesInfo } from '@view/Roles/router';
import { routerUsers } from '@view/Users/router';

import { IRouter } from './interface';

export const privatePage: IRouter[] = [
  routerHome,
  routerUsers,
  routerCustomer,
  routerDevice,
  routerRoles,
  routerRolesAdd,
  routerRolesEdit,
  routerRolesInfo,
  routerViewProfile,
  routerLogs,
  routerPageError,
];

export const publicPage: IRouter[] = [routerLogin, routerPageError];
