export default {
  'menu.name': 'Quản lý thực đơn',
  'menu.menuName': 'Tên thực đơn',
  'menu.menuTags': 'Tag thực đơn',
  'menu.internalComment': 'Ghi chú',
  'menu.isActive': 'Trạng thái hoạt động',
  'menu.create': 'Thêm thực đơn',
  'menu.update': 'Cập nhật thực đơn',
  'menu.information': 'Thông tin thực đơn',
  'menu.delete': 'Xóa thực đơn',
  'menu.delete.title': 'Xóa thực đơn',
  'menu.content.delete': 'Bạn có chắc xóa thực đơn. Thực đơn bị xóa sẽ không thể không phục',
  'menu.copy.delete': 'Bạn có chắc chắn muốn sao chép thực đơn này không?',
  'products.accept': 'Sắp xếp',
};
