export default {
  'users.name': 'User management',
  'users.userName': 'user name',
  'users.fullName': 'Full name',
  'users.phone': 'Phone number',
  'users.isActive': 'Status',
  'users.action': 'Act',
  'users.internalComment': 'Note',
  'users.update': 'Update users',
  'users.information': 'User information',
  'users.password': 'Password',
  'users.userType': 'Role',
  'users.create': 'Create user',
  'users.userType.admin': 'Management',
  'users.userType.accountant': 'Accountant',
  'users.userType.top.up': 'Recharge',
  'transaction.order': 'Purchase',
  'users.userType.order': 'Sell',
  'users.delete': 'Delete users',
  'user.content.delete': 'Are you sure you want to delete this user?',
};
